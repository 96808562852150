const messages = {
  general: {
    id: "Id",
  },
  advertisement: {
    listTitle: "Advertisement",
    editTitle: "Edit advertisement",
    createTitle: "Create advertisement",
    externalAdvertisementListTitle: "External advertisement",
  },
  user: {
    listTitle: "Users",
    createTitle: "Create user",
    editTitle: "Edit user",
  },
};

export default messages;
