import { useCallback } from "react";
import useConfig from "./useConfig";

const useFileStorage = () => {
  const config = useConfig();

  const processUpload = useCallback(async (file: File) => {
    var formData = new FormData();

    formData.append(
      "operations",
      `{ "query": "mutation ($file: Upload!) { file: uploadFile(file: $file) {id filename generatedId filename mimetype path encoding } }", "variables": { "file": null } }`
    );

    formData.append("map", `{"0": ["variables.file"]}`);
    formData.append("0", file);

    const response = await fetch(config.URL_API, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data.data.file;
  }, [config.URL_API]);

  return {
    processUpload,
  };
};

export default useFileStorage;
