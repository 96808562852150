import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  ReferenceArrayInput,
  NumberInput,
  required,
} from "react-admin";
import { EditActions } from "../../components/ra/Actions";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";

const ArticleEdit = ({ ...props }) => (
  <Edit
    actions={<EditActions configuration />}
    title={"article.editTitle"}
    {...props}
  >
    <SimpleForm defaultValue={{ type: "article" }}>
      {/* <NumberInput label={"general.ordering"} source="ordering" /> */}
      <TextInput label={"page.slug"} source="slug" />
      <BooleanInput label={"page.published"} source="published" />
      {/* <ReferenceInput
        label="page.category"
        source="categoryId"
        reference="Category"
      >
        <SelectInput optionText="title" />
      </ReferenceInput> */}
      {/* <ReferenceArrayInput source="tagIds" reference="Tag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput> */}
      <TextInput label={"page.title"} validate={[required()]} source="title" />
      <TextInput fullWidth label={"page.description"} source="description" />
      <RichTextEditor label={"page.content"} source="content" />
    </SimpleForm>
  </Edit>
);

export default ArticleEdit;
