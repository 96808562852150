import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  EditProps,
  required,
} from "react-admin";
import { useTranslate } from "ra-core";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";
import { EditActions } from "../../components/ra/Actions";

const PageEdit = ({ permissions, ...props }: EditProps) => {
  const t = useTranslate();
  return (
    <Edit actions={<EditActions configuration />} title={"page.editTitle"} {...props}>
      <SimpleForm defaultValue={{ type: "page" }}>
        <NumberInput label={"general.ordering"} source="ordering" />
        <BooleanInput label={"page.published"} source="published" />
        <ReferenceInput
          validate={[required()]}
          label="page.category"
          source="categoryId"
          reference="Category"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        {/* <ReferenceArrayInput source="tagIds" reference="Tag">
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput> */}
        <TextInput label={"page.slug"} source="slug" />
        <TextInput
          validate={[required()]}
          label={"page.title"}
          source="title"
        />
        <TextInput fullWidth label={"page.description"} source="description" />
        <RichTextEditor label={"page.content"} source="content" />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
