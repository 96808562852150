import { Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

const CLOSED_WIDTH = 55;
const OPEN_WIDTH = 240;

const useSidebarStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
  },
  drawerPaper: {
    backgroundColor: theme.palette.grey[100],
    minHeight: "100vh",
  },
  fixed: ({ sideBarOpen }: any) => ({
    position: "static",
    height: "calc(100vh - 3em)",
    width: !sideBarOpen ? CLOSED_WIDTH : OPEN_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  divider: ({ sideBarOpen }: any) => ({
    maxWidth: 40,
    width: "100%",
    background: theme.palette.grey[200],
    margin: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: sideBarOpen ? "block" : "none",
  }),
}));

const MySidebar = (props: any) => {
  const sideBarOpen = useSelector((state: any) => {
    return state?.admin?.ui?.sidebarOpen;
  });
  const classes = useSidebarStyles({
    sideBarOpen,
  });

  const { divider, ...restClasses } = classes;

  return (
    <Sidebar classes={restClasses} {...props}>
      <Logo simple={!sideBarOpen} />
      <div className={classes?.divider}>
        <Divider />
      </div>
      {props.children}
    </Sidebar>
  );
};

export default MySidebar;
