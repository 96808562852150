import {
  Edit,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  required,
  SimpleForm,
} from "react-admin";
import { EditActions } from "../../components/ra/Actions";
import { parse } from "query-string";

const ComponentEdit = ({ ...props }) => {
  const {
    parent_id: parent_id_string,
    redirect_to,
    template,
  }: any = parse(props.location.search);

  const parent_id = parent_id_string ? parseInt(parent_id_string, 10) : "";

  // Redirect to Section
  const redirect = parent_id
    ? `/${redirect_to}/${parent_id}/show/components`
    : "list";
  return (
    <Edit
      actions={<EditActions configuration />}
      title={"component.editTitle"}
      {...props}
    >
      <SimpleForm redirect={redirect} defaultValue={{ type: "component" }}>
        <TextInput label={"general.name"} source="name" />
        <TextInput
          label={"general.title"}
          validate={[required()]}
          source="title"
        />
        <ReferenceInput
          label="general.section"
          source="parentId"
          reference="Section"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput label={"general.ordering"} source="ordering" />
      </SimpleForm>
    </Edit>
  );
};

export default ComponentEdit;
