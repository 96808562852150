import { Create, required, SimpleForm, TextInput } from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

const GlobalCreate = ({ onCancel, ...props }: any) => (
  <Create
    actions={<CreateActions />}
    redirect="list"
    title={"global.createTitle"}
    {...props}
  >
    <SimpleForm>
      <TextInput validate={[required()]} label={"general.code"} source="name" />
      <TextInput
        validate={[required()]}
        label={"general.name"}
        source="title"
      />
    </SimpleForm>
  </Create>
);

export default GlobalCreate;
