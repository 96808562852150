import {
  TextField,
  Datagrid,
  ReferenceManyField,
  ChipField,
  EditProps,
  Show,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import { useTranslate } from "ra-core";
import AddPageSectionButton from "./_components/AddPageSectionButton";
import EditSectionButton from "./_components/EditSectionButton";
import EditPageSectionButton from "./_components/EditPageSectionButton";
import AddPropertyButton from "./_components/AddPropertyButton";
import EditPropertyButton from "./_components/EditPropertyButton";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import { AspectRatioOutlined, ClearAllOutlined } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { ShowActions } from "../../components/ra/Actions";
import DeletePropButton from "./_components/DeletePropButton";
import DeletePageSectionButton from "../advertisement/_components/DeletePageSectionButton";
import ShowSectionButton from "./_components/ShowSectionButton";

const PageShow = ({ permissions, ...props }: EditProps) => {
  const t = useTranslate();
  return (
    <Show actions={<ShowActions />} title={"page.showTitle"} {...props}>
      <TabbedShowLayout>
        <Tab icon={<ClearAllOutlined />} label="general.properties">
          <ReferenceManyField
            fullWidth
            addLabel={false}
            reference="Property"
            target="postId"
            sort={{ field: "id", order: "DESC" }}
          >
            <Datagrid>
              <ChipField source="name" label="general.code" />
              <TextField source="title" label="general.name" />
              <PropertyValueField source="value" label="general.value" />
              <EditPropertyButton />
              <DeletePropButton />
            </Datagrid>
          </ReferenceManyField>
          <Box height={10} />

          <AddPropertyButton />
          <Box height={10} />
        </Tab>
        <Tab
          path="sections"
          icon={<AspectRatioOutlined />}
          label="general.sections"
        >
          <ReferenceManyField
            fullWidth
            addLabel={false}
            reference="PostSection"
            target="postId"
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="component.title" label="page.sectionTitle" />
              <TextField source="component.name" label="page.sectionCode" />
              <TextField source="ordering" label={"general.ordering"} />
              {/* <TextField source="post.title" label="page.postTitle" /> */}
              {permissions?.includes("administrator") && (
                <EditPageSectionButton />
              )}
              <EditSectionButton />
              <ShowSectionButton />
              {/* <DeletePageSectionButton /> */}
            </Datagrid>
          </ReferenceManyField>
          <Box height={10} />

          {permissions?.includes("administrator") && <AddPageSectionButton />}
          <Box height={10} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PageShow;
