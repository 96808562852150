export interface Config {
  URL_API: string;
}

const env = (env: string) => {
  return process.env[`REACT_APP_${env}`] || "";
};

const config = {
  URL_API: env("API_URL"),
  FILE_SERVICE: env("FILE_SERVICE"),
};

export default config;
