import {
  BooleanInput,
  Edit,
  TextInput,
  NumberInput,
  required,
  SimpleForm,
} from "react-admin";
import { useTranslate } from "ra-core";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";
import { EditActions } from "../../components/ra/Actions";

const AdvertisementEdit = ({ ...props }) => {
  const t = useTranslate();
  return (
    <Edit
      actions={<EditActions configuration />}
      title={"advertisement.editTitle"}
      {...props}
    >
      <SimpleForm defaultValue={{ type: "advertisement" }}>
        <TextInput label={"page.slug"} source="slug" />
        <BooleanInput label={"page.published"} source="published" />
        <NumberInput label={"general.ordering"} source="ordering" />
        {/* <TextInput label={"page.slug"} source="slug" /> */}
        <TextInput
          label={"page.title"}
          validate={[required()]}
          source="title"
        />
        <TextInput fullWidth label={"page.description"} source="description" />
        <RichTextEditor label={"page.content"} source="content" />
      </SimpleForm>
    </Edit>
  );
};

export default AdvertisementEdit;
