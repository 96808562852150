import {
  Datagrid,
  DateField,
  TextField,
  EditButton,
  List,
  Responsive,
  ChipField,
} from "react-admin";
import SettingsButton from "../../components/ra/SettingsButton";

const GlobalList = ({ ...props }) => {
  return (
    <List
      bulkActionButtons={false}
      sort={{ field: "id", order: "desc" }}
      title={"global.listTitle"}
      {...props}
    >
      <Responsive
        small={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <ChipField label={"general.code"} source="name" />
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
        medium={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <ChipField label={"general.code"} source="name" />
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default GlobalList;
