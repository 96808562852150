import { parse } from "querystring";
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { EditActions } from "../../components/ra/Actions";

const SectionEdit = ({ ...props }) => {
  const { page_id: page_id_string, redirect_to }: any = parse(
    props.location.search
  );

  const page_id = page_id_string ? parseInt(page_id_string, 10) : "";

  const redirect = page_id
    ? `/${redirect_to}/${page_id}/show/sections`
    : "list";

  return (
    <Edit
      actions={<EditActions configuration />}
      title={"section.editTitle"}
      {...props}
    >
      <SimpleForm redirect={redirect} defaultValue={{ type: "section" }}>
        <TextInput
          validate={[required()]}
          label={"general.code"}
          source="name"
        />
        <TextInput
          validate={[required()]}
          label={"general.name"}
          source="title"
        />
      </SimpleForm>
    </Edit>
  );
};

export default SectionEdit;
