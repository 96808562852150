import { Edit, required, SimpleForm, TextInput } from "react-admin";
import { EditActions } from "../../components/ra/Actions";
import { EditToolbar } from "../../components/ra/Toolbars";

const CategoryEdit = ({ ...props }) => (
  <Edit actions={<EditActions />} title={"category.editTitle"} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput label={"general.code"} source="name" />
      <TextInput
        label={"general.name"}
        validate={[required()]}
        source="title"
      />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
