import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { DataProvider } from "react-admin";
import apiService from "./services/api.service";

const provider = (
  client: ApolloClient<NormalizedCacheObject>
): DataProvider => {
  const serviceProvider = apiService(client);
  return {
    getList: (resource, params) => {
      return serviceProvider.getList(resource, params);
    },

    getOne: (resource, params) => {
      return serviceProvider.getOne(resource, params);
    },

    getMany: serviceProvider.getMany,

    getManyReference: (resource, params) => {
      return serviceProvider.getManyReference(resource, params);
    },

    update: serviceProvider.update,

    updateMany: serviceProvider.updateMany,

    create: (resource, params) => {
      return serviceProvider.create(resource, params);
    },

    delete: serviceProvider.delete,

    deleteMany: serviceProvider.deleteMany,
  };
};

export default provider;
