import {
  TextField,
  Datagrid,
  ReferenceManyField,
  ChipField,
  Show,
  SimpleShowLayout,
} from "react-admin";
import { useTranslate } from "ra-core";
import AddPropertyButton from "./_components/AddPropertyButton";
import EditPropertyButton from "./_components/EditPropertyButton";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import { ShowActions } from "../../components/ra/Actions";

const AdvertisementShow = ({ ...props }) => {
  const t = useTranslate();
  return (
    <Show
      actions={<ShowActions />}
      title={"advertisement.showTitle"}
      {...props}
    >
      <SimpleShowLayout>
        <ReferenceManyField
          fullWidth
          label="property.relation"
          reference="Property"
          target="postId"
          sort={{ field: "id", order: "DESC" }}
        >
          <Datagrid>
            <ChipField source="name" label="general.code" />
            <TextField source="title" label="general.name" />
            <PropertyValueField source="value" label="general.value" />
            <EditPropertyButton />
          </Datagrid>
        </ReferenceManyField>
        <AddPropertyButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default AdvertisementShow;
