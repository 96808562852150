import { makeStyles } from "@material-ui/core";
import { FieldProps, useRecordContext } from "react-admin";
import useConfig from "../../../hooks/useConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootRounded: ({ size }: { size?: number }) => ({
    height: size,
    width: size,
    objectFit: "cover",
    borderRadius: "90%",
    overflow: "hidden",
  }),
  rootSquare: ({ size }: { size?: number }) => ({
    height: size,
    width: "auto",
    borderRadius: 0,
    objectFit: "cover",
    overflow: "hidden",
  }),
  loading: {
    color: theme.palette.secondary.main,
    margin: "auto",
  },
}));

type Props = {
  size?: number;
  square?: boolean;
};

const ThumbnailField = ({
  size = 25,
  square = true, // TODO: add file path attribute to props
  ...rest
}: FieldProps & Props) => {
  const record = useRecordContext(rest);
  const classes = useStyles({ size });
  const config = useConfig();
  
  return (
    <span className={classes.root}>
      <img
        className={square ? classes.rootSquare : classes.rootRounded}
        src={config.FILE_SERVICE + "/" + record?.path}
        alt="preview"
      />
    </span>
  );
};

export default ThumbnailField;
