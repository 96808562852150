import { useCallback, useState } from "react";
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useTranslate,
  useUpdate,
  ReferenceInput,
  required,
  SelectInput,
  useRefresh,
  useRecordContext,
  EditContext,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function QuickAddComponentButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate("Component");
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const globalRecord = useRecordContext();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = useCallback(
    (values: any) => {
      update(
        {
          payload: {
            data: { id: values.id, type: "component", globalId: globalRecord.id },
          },
        },
        {
          onSuccess: ({ data }) => {
            setShowDialog(false);
            refresh();
          },
          onFailure: ({ error }) => {
            setShowDialog(false);
            notify(error.message, "error");
          },
        }
      );
    },
    [setShowDialog, notify, globalRecord.id, refresh, update]
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        label="general.button.addComponent"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("global.dialog.addComponent.title")}
      >
        <DialogTitle>{translate("global.dialog.addComponent.title")}</DialogTitle>
        <FormWithRedirect
          resource="Component"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  validate={[required()]}
                  label="general.component"
                  source="id"
                  reference="Component"
                >
                  <SelectInput optionText="title" />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  label="general.button.addComponent"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default QuickAddComponentButton;
