import React from "react";
import { AppBar, UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const ConfigurationMenu = React.forwardRef(
  ({ onClick }: { onClick?: any }, ref: any) => (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText="Configuration"
      leftIcon={<SettingsIcon />}
      onClick={onClick} // close the menu on click
    />
  )
);

const MyUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  appbar: {
    position: "static",
    backgroundColor: theme.palette.primary.main,
  },
}));

const MyAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appbar} {...props} userMenu={<MyUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
