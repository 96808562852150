import { Box } from "@material-ui/core";
import { ClearAllOutlined, GroupWorkOutlined } from "@material-ui/icons";
import {
  Datagrid,
  TextField,
  Show,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  ChipField,
} from "react-admin";
import { ShowActions } from "../../components/ra/Actions";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import AddNewChildrenButton from "./_components/AddNewComponentButton";
import AddPropertyButton from "./_components/AddPropertyButton";
import DeleteComponnetButton from "./_components/DeleteComponnetButton";
import EditChildrenButton from "./_components/EditComponentButton";
import EditPropertyButton from "./_components/EditPropertyButton";

const SectionShow = ({ ...props }) => {
  return (
    <Show actions={<ShowActions />} title={"section.showTitle"} {...props}>
      <TabbedShowLayout>
        <Tab icon={<ClearAllOutlined />} label="general.properties">
          <ReferenceManyField
            addLabel={false}
            reference="Property"
            target="componentId"
            sort={{ field: "id", order: "DESC" }}
          >
            <Datagrid>
              <ChipField source="name" label="general.code" />
              <TextField source="title" label="general.name" />
              <PropertyValueField source="value" label="general.value" />
              <TextField source="ordering" label="general.ordering" />
              <EditPropertyButton />
            </Datagrid>
          </ReferenceManyField>
          <AddPropertyButton />
          <Box height={10} />
        </Tab>
        <Tab
          icon={<GroupWorkOutlined />}
          path="components"
          label="general.components"
        >
          <ReferenceManyField
            addLabel={false}
            reference="Component"
            target="parentId"
            sort={{ field: "id", order: "DESC" }}
          >
            <Datagrid>
              <ChipField source="name" label="general.code" />
              <TextField source="title" label="general.name" />
              <TextField source="ordering" label="general.ordering" />
              <EditChildrenButton />
              <DeleteComponnetButton />
            </Datagrid>
          </ReferenceManyField>
          <AddNewChildrenButton />
          <Box height={10} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SectionShow;
