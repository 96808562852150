import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, useTranslate } from "react-admin";
import { Add } from "@material-ui/icons";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddPageSectionButton = ({ classes, record }: any) => {
  return (
    <Button
      className={classes.button}
      variant="outlined"
      component={Link}
      to={`/postSection/create?post_id=${record.id}&redirect_to=page`}
      label={"general.button.addSection"}
      title={"general.button.addSection"}
    >
      <Add />
    </Button>
  );
};

export default withStyles(styles)(AddPageSectionButton);
