import pluralize from "pluralize";
import gql from "graphql-tag";

export const getList = (resource: string, fields: string) => {
  return gql`query pageOf${pluralize.plural(resource)}(
    $pagination: Pagination, 
    $filter: String
  ) {
    data: pageOf${pluralize.plural(resource)}(
      pagination: $pagination, 
      filter: $filter
    ) {
      content {
          ${fields}
      }
      total
    }
  }`;
};

export const getOne = (resource: string, fields: string) => {
  return gql`query find${resource}ById($id: Int!) {
    data: find${resource}ById(id: $id) {
      ${fields}
    }
  }`;
};

export const getMany = (resource: string, fields: string) => {
  return gql`query getPageOf${pluralize.plural(resource)} (
    $pagination: Pagination, 
    $filter: String
  ) {
    data: pageOf${pluralize.plural(resource)}(
      pagination: $pagination, 
      filter: $filter
    ) {
      content {
        ${fields}
      }
      total
    }
  }`;
};

export const getManyReference = (resource: string, fields: string) => {
  return gql`query getPageOf${pluralize.plural(resource)}(
      $pagination: Pagination, 
      $filter: String
    ) {
    data: pageOf${pluralize.plural(resource)}(
      pagination: $pagination, 
      filter: $filter
      ) {
        content {
          ${fields}
        }
        total
    }
  }`;
};

export const update = (
  resource: string,
  fields: string,
  inputType: string = "String!"
) => {
  return gql`mutation update${resource}($data: ${inputType}, $id: Int!) {
    data: update${resource}(data: $data, id: $id) {
        ${fields}
    }
  }`;
};

export const updateMany = (resource: string, fields: string, type: string) => {
  return ``;
};

export const create = (
  resource: string,
  fields: string,
  inputType: string = "String!"
) => {
  return gql`mutation create${resource}($data: ${inputType}) {
    data: create${resource}(data: $data) {
        ${fields}
    }
  }`;
};

export const remove = (resource: string, fields: string) => {
  return gql`mutation delete${resource}($id: Int!) {
    data: delete${resource}(id: $id) {
        ${fields}
    }
  }`;
};

export const removeMany = (resource: string, fields: string) => {
  return gql`mutation delete${pluralize.plural(resource)}($ids: [Int!]!) {
    data: delete${pluralize.plural(resource)}(ids: $ids) 
  }`;
};

const queries = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  remove,
  removeMany,
};

export default queries;
