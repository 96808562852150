import GalleryCreate from "./gallery.create";
import GalleryList from "./gallery.list";
import GalleryEdit from "./gallery.edit";
import GalleryShow from "./gallery.show";

export default {
  create: GalleryCreate,
  list: GalleryList,
  edit: GalleryEdit,
  show: GalleryShow,
};
