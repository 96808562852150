import {
  Datagrid,
  DateField,
  TextField,
  EditButton,
  List,
  Responsive,
  FunctionField,
} from "react-admin";
import ImageField from "../../components/ra/fields/ThumbnailField";
import useConfig from "../../hooks/useConfig";

const FileList = ({ ...props }) => {
  const config = useConfig();
  return (
    <List
      sort={{ field: "id", order: "desc" }}
      title={"file.listTitle"}
      {...props}
    >
      <Responsive
        small={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <TextField label={"file.name"} source="filename" />
            <TextField label={"file.mimetype"} source="mimetype" />
            <FunctionField
              label={"file.path"}
              render={(record: any) =>
                record?.path && (
                  <span>
                    <a href={config.FILE_SERVICE + "/" + record?.path}>link</a>
                  </span>
                )
              }
            />
            <DateField label={"general.createdAt"} source="createdAt" />
            <EditButton />
          </Datagrid>
        }
        medium={
          <Datagrid>
            <ImageField />
            <TextField label={"general.id"} source="id" />
            <TextField label={"file.name"} source="filename" />
            <TextField label={"file.mimetype"} source="mimetype" />
            <FunctionField
              label={"file.path"}
              render={(record: any) =>
                record?.path && (
                  <span>
                    <a href={config.FILE_SERVICE + "/" + record?.path}>link</a>
                  </span>
                )
              }
            />
            <DateField label={"general.createdAt"} source="createdAt" />
            {/* <EditButton /> */}
          </Datagrid>
        }
      />
    </List>
  );
};

export default FileList;
