import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { Edit } from "@material-ui/icons";

const styles = {
  button: {
  },
};

const EditChildrenButton = ({ classes, record }: any) => (
  <Button
    className={classes.button}
    variant="outlined"
    component={Link}
    to={`/component/${record.id}/edit?parent_id=${record.parentId}&redirect_to=section`}
    label="section.button.editChildren"
    title="section.button.editChildren"
  >
    <Edit />
  </Button>
);

export default withStyles(styles)(EditChildrenButton);
