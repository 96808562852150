import {
  List,
  TextField,
  EditButton,
  Responsive,
  DateField,
} from "react-admin";
import Datagrid from "../../components/ra/Datagrid";

const UserList = ({ ...props }) => (
  <List
    title={"user.listTitle"}
    // filterDefaultValues={{ email: "" }}
    {...props}
  >
    <Responsive
      small={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <TextField label={"user.login"} source="login" />
          <TextField label={"user.email"} source="email" />
          <TextField label={"user.name"} source="name" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <EditButton />
        </Datagrid>
      }
      medium={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <TextField label={"user.login"} source="login" />
          <TextField label={"user.email"} source="email" />
          <TextField label={"user.name"} source="name" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default UserList;
