import { Link } from "react-router-dom";
import Edit from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const EditPropertyButton = ({ classes, record }: any) => {
  return (
    <Button
      className={classes.button}
      variant="outlined"
      component={Link}
      to={`/property/${record.id}/edit?post_id=${record?.postId}&redirect_to=article`}
      label="article.button.editProp"
      title="article.button.editProp"
    >
      <Edit />
    </Button>
  );
};

export default withStyles(styles)(EditPropertyButton);
