import {
  Datagrid,
  DateField,
  TextField,
  EditButton,
  List,
  Responsive,
  NumberField,
  ChipField,
} from "react-admin";
import SettingsButton from "../../components/ra/SettingsButton";

const GalleryList = ({ ...props }) => {
  return (
    <List
      sort={{ field: "id", order: "desc" }}
      title={"gallery.listTitle"}
      {...props}
    >
      <Responsive
        small={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            {/* <ChipField label={"general.key"} source="name" /> */}
            {/* <TextField label={"general.type"} source="type" /> */}
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
        medium={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            {/* <ChipField label={"general.key"} source="name" /> */}
            {/* <TextField label={"general.type"} source="type" /> */}
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            {/* <NumberField label={"general.ordering"} source="ordering" /> */}
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default GalleryList;
