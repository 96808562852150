import { Datagrid as RaDatagrid } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    "& td": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    height: 40,
  },
}));

const Datagrid = ({ ...props }) => {
  const classes = useStyles();

  return (
    <RaDatagrid size="small" classes={classes} {...props}>
      {props.children}
    </RaDatagrid>
  );
};

export default Datagrid;
