import {
  Datagrid,
  DateField,
  EditButton,
  List,
  Responsive,
  TextField,
} from "react-admin";
// filterDefaultValues={{ email: "" }}

const PostSectionList = ({ ...props }) => (
  <List
    sort={{ field: "id", order: "desc" }}
    title={"postSection.listTitle"}
    {...props}
  >
    <Responsive
      small={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <TextField label={"general.name"} source="name" />
          <TextField label={"general.type"} source="type" />
          <TextField label={"general.value"} source="value" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <EditButton />
        </Datagrid>
      }
      medium={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <TextField label={"general.name"} source="name" />
          <TextField label={"general.type"} source="type" />
          <TextField label={"general.value"} source="value" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default PostSectionList;
