import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import Edit from "@material-ui/icons/Edit";

const styles = {
  button: {},
};

const EditPageSectionButton = ({ classes, record }: any) => {
  return (
    <Button
      className={classes.button}
      variant="outlined"
      component={Link}
      to={`/postSection/${record?.id}/edit?post_id=${record?.post?.id}&component_id=${record?.component?.id}&redirect_to=page`}
      label="page.button.editPageSection"
      title="page.button.editPageSection"
    >
      <Edit />
    </Button>
  );
};

export default withStyles(styles)(EditPageSectionButton);
