import {
  Datagrid,
  DateField,
  SelectInput,
  TextField,
  EditButton,
  List,
  Responsive,
  BooleanField,
} from "react-admin";

const formDataFilter = [
  <SelectInput
    alwaysOn
    label="general.type"
    choices={[
      { id: "contact_form", name: "Kontaktný formulár" },
      { id: "hiring", name: "Hiring formulár" },
      { id: "bonuscard", name: "Bonusová karta" },
    ]}
    source="type"
  />,
];

const FormDataList = ({ ...props }) => {
  return (
    <List
      sort={{ field: "id", order: "desc" }}
      filters={formDataFilter}
      title={"formData.listTitle"}
      {...props}
    >
      <Responsive
        small={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <TextField label={"general.type"} source="type" />
            <TextField label={"general.name"} source="name" />
            <TextField label={"general.email"} source="email" />
            <TextField label={"formData.message"} source="message" />
            <TextField label={"formData.telephone"} source="telephone" />
            <TextField label={"formData.city"} source="city" />
            <TextField label={"formData.street"} source="street" />
            <TextField label={"formData.zip"} source="zip" />
            <BooleanField
              label={"formData.gdprApproval"}
              source="gdprApproval"
            />
            <DateField label={"general.createdAt"} source="createdAt" />
            <EditButton />
          </Datagrid>
        }
        medium={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <TextField label={"general.type"} source="type" />
            <TextField label={"general.name"} source="name" />
            <TextField label={"general.email"} source="email" />
            <TextField label={"formData.message"} source="message" />
            <TextField label={"formData.telephone"} source="telephone" />
            <TextField label={"formData.city"} source="city" />
            <TextField label={"formData.street"} source="street" />
            <TextField label={"formData.zip"} source="zip" />
            <BooleanField
              label={"formData.gdprApproval"}
              source="gdprApproval"
            />
            <DateField label={"general.createdAt"} source="createdAt" />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default FormDataList;
