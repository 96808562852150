import { withStyles } from "@material-ui/core/styles";
import { DeleteButton } from "react-admin";

const styles = {};

const DeletePropertyButton = ({ classes, record, ...rest }: any) => {
  return (
    <DeleteButton
      record={record}
      {...rest}
      redirect={`/global/${record.globalId}/show`}
    />
  );
};

export default withStyles(styles)(DeletePropertyButton);
