import { Edit, required, SimpleForm, TextInput } from "react-admin";

const TagEdit = ({ ...props }) => (
  <Edit title={"tag.editTitle"} {...props}>
    <SimpleForm>
      <TextInput label={"general.code"} source="name" />
      <TextInput
        validate={[required()]}
        label={"general.name"}
        source="title"
      />
    </SimpleForm>
  </Edit>
);

export default TagEdit;
