import AdvertisementCreate from "./advertisement.create";
import AdvertisementList from "./advertisement.list";
import AdvertisementEdit from "./advertisement.edit";
import AdvertisementShow from "./advertisement.show";

export default {
  create: AdvertisementCreate,
  list: AdvertisementList,
  edit: AdvertisementEdit,
  show: AdvertisementShow,
};
