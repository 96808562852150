import { CoreLayoutProps } from "react-admin";
import AppBar from "./AppBar";
import Sidebar from "./SideBar";
import Menu from "./Menu";
import Notification from "./Notification";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { setSidebarVisibility } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../../config/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
    // height: "100vh",
    // overflow: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    height: "100vh",
    // left: 300,
    // width: `calc(100vw - 300px)`,
    // height: "100vh",
    overflowX: "auto",
    overflowY: "auto",
  },
  appbarWithContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: theme.palette.grey[300],
    height: "100vh",
    overflowX: "auto",
    overflowY: "auto",
  },
}));

const BaseLayout = ({ children, logout, title }: CoreLayoutProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <main className={classes.contentWithSidebar}>
            <Sidebar>
              <Menu />
            </Sidebar>
            <div className={classes.appbarWithContent}>
              <AppBar title={title} open={open} logout={logout} />
              <div className={classes.content}>{children}</div>
            </div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default BaseLayout;
