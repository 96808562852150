import {
  PeopleOutlineOutlined,
  DescriptionOutlined,
  GroupOutlined,
  GroupWorkOutlined,
  AssignmentOutlined,
  LocalOfferOutlined,
  AspectRatioOutlined,
  ClearAllOutlined,
  MessageOutlined,
  PersonOutline,
  AttachmentOutlined,
  ImageOutlined,
  CategoryOutlined,
  FeaturedVideoOutlined,
} from "@material-ui/icons";
import Settings from "@material-ui/icons/Settings";
import CustomMenuItemLink from "./MenuItemLink";

const Menu = () => (
  <div>
    <CustomMenuItemLink
      to="/user"
      primaryText="user.listTitle"
      leftIcon={<PersonOutline />}
    />
    <CustomMenuItemLink
      to="/role"
      primaryText="role.listTitle"
      leftIcon={<GroupOutlined />}
    />
    <CustomMenuItemLink
      to="/page"
      primaryText="page.listTitle"
      leftIcon={<DescriptionOutlined />}
    />
    <CustomMenuItemLink
      to="/advertisement"
      primaryText="advertisement.listTitle"
      leftIcon={<FeaturedVideoOutlined />}
    />
    <CustomMenuItemLink
      to="/category"
      primaryText="category.listTitle"
      leftIcon={<CategoryOutlined />}
    />
    <CustomMenuItemLink
      to="/article"
      primaryText="article.listTitle"
      leftIcon={<AssignmentOutlined />}
    />
    {/* <CustomMenuItemLink
      to="/fbFeed"
      primaryText="fbFeed.listTitle"
      leftIcon={<DescriptionOutlined />}
    /> */}
    <CustomMenuItemLink
      to="/tag"
      primaryText="tag.listTitle"
      leftIcon={<LocalOfferOutlined />}
    />
    <CustomMenuItemLink
      to="/component"
      primaryText="component.listTitle"
      leftIcon={<GroupWorkOutlined />}
    />
    <CustomMenuItemLink
      to="/file"
      primaryText="file.listTitle"
      leftIcon={<AttachmentOutlined />}
    />
    <CustomMenuItemLink
      to="/gallery"
      primaryText="gallery.listTitle"
      leftIcon={<ImageOutlined />}
    />
    <CustomMenuItemLink
      to="/section"
      primaryText="section.listTitle"
      leftIcon={<AspectRatioOutlined />}
    />
    <CustomMenuItemLink
      to="/property"
      primaryText="property.listTitle"
      leftIcon={<ClearAllOutlined />}
    />
    <CustomMenuItemLink
      to="/formData"
      primaryText="formData.listTitle"
      leftIcon={<MessageOutlined />}
    />
    <CustomMenuItemLink
      to="/global"
      primaryText="global.listTitle"
      leftIcon={<Settings />}
    />
  </div>
);

export default Menu;
