import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import config from "../config/config";

const httpLink = new HttpLink({
  uri: config.URL_API,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const httpHeaders = {
    headers: {
      ...headers,
    },
  };

  return httpHeaders;
});

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("user");
        window.location.reload();
      }

      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([logoutLink, authLink, httpLink]),
  cache: new InMemoryCache().restore({}),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default apolloClient;
