import { useDelete, useInput } from "react-admin";
import { useCallback, useEffect, useState } from "react";
import Dropzone from "../../shared/Dropzone/Dropzone";
import useFileStorage from "../../../hooks/useFileStorage";

const BoundedFileInput = (props: any) => {
  const { processUpload } = useFileStorage();
  const {
    input: { name, onChange, value, ...rest },
  } = useInput(props);

  const [deleteOne, { loading, error }] = useDelete();

  let defaultValue = value;
  try {
    defaultValue = JSON.parse(value);
  } catch (err) {
    console.warn(`Could not parse value`, err);
  }

  const [uploadedFiles, setUploadedFiles] = useState<any>(
    typeof defaultValue === "string" ? [] : defaultValue
  );

  const addUploadedFiles = useCallback(
    (f) => {
      if (props.multiple === false) {
        setUploadedFiles(f);
      } else {
        setUploadedFiles([...uploadedFiles, ...f]);
      }
    },
    [uploadedFiles, props.multiple, setUploadedFiles]
  );

  useEffect(() => {
    onChange(JSON.stringify(uploadedFiles));
  }, [uploadedFiles, onChange]);

  const handleDelete = useCallback(
    (file: any) => {
      deleteOne("File", file.id, file);
      setUploadedFiles(uploadedFiles.filter((x: any) => x.id !== file.id));
    },
    [uploadedFiles, deleteOne, setUploadedFiles]
  );

  const handleUpload = useCallback(
    (files: Array<File>) => {
      if (files.length === 0) {
        return;
      }

      const uploadedFiles: File[] = [];
      let counter = 0;
      files.forEach(async (file) => {
        const uploadedFile = await processUpload(file);
        uploadedFiles.push(uploadedFile);
        counter++;
        if (files.length === counter) {
          addUploadedFiles(uploadedFiles);
        }
      });
    },
    [processUpload, addUploadedFiles]
  );

  return (
    <>
      <Dropzone
        accept={["image/*", "application/pdf"]}
        multiple={props.multiple}
        files={uploadedFiles}
        onDrop={handleUpload}
        onDeleteClick={handleDelete}
        onAttachmentClick={() => {}}
        {...rest}
      />
    </>
  );
};

const FileInput = (props: any) => {
  const { source, ...rest } = props;

  return (
    <span>
      <BoundedFileInput source={source} {...rest} />
    </span>
  );
};

export default FileInput;
