import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import gql from "graphql-tag";
import { DataProvider } from "react-admin";
import query from "../../config/query";

const apiService = (
  apolloClient: ApolloClient<NormalizedCacheObject>
): DataProvider => ({
  getList: (resource, params) => {
    return apolloClient
      .query({
        query: query.getList({ resource }),
        variables: {
          filter: JSON.stringify(params.filter),
          pagination: {
            page: params.pagination.page - 1,
            perPage: params.pagination.perPage,
            sortField: params.sort.field,
            sortOrder: params.sort.order,
          },
        },
      })
      .then(({ data }) => {
        return {
          data: data?.data?.content,
          total: data?.data?.total,
        };
      });
  },
  getOne: (resource, params) => {
    return apolloClient
      .query({
        query: query.getOne({ resource }),
        variables: { id: Number(params.id) },
      })
      .then(({ data }: any) => {
        return data;
      });
  },

  getMany: (resource, params) => {
    return apolloClient
      .query({
        query: query.getMany({ resource }),
        variables: {
          filter: JSON.stringify({ ids: params.ids }),
          pagination: {
            page: 0,
            perPage: 1000,
            sortField: "id",
            sortOrder: "desc",
          },
        },
      })
      .then(({ data }: any) => {
        return { data: data?.data?.content };
      });
  },

  getManyReference: (resource, params) => {
    return apolloClient
      .query({
        query: query.getManyReference({ resource }),
        variables: {
          filter: JSON.stringify({ [params.target]: params.id }),
          pagination: {
            page: params.pagination.page - 1,
            perPage: params.pagination.perPage,
            sortField: params.sort.field,
            sortOrder: params.sort.order,
          },
        },
      })
      .then(({ data }: any) => {
        return {
          data: data?.data?.content,
          total: data?.data?.total,
        };
      });
  },

  update: (resource, params) => {
    const id = params.data.id;
    delete params.data.id;
    delete params.data.__typename;
    delete params.data.createdAt;
    return apolloClient
      .mutate({
        mutation: query.update({ resource }),
        variables: { data: params.data, id },
      })
      .then(({ data }: any) => {
        return {
          data: data?.data,
        };
      });
  },
  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) => apolloClient.mutate({ mutation: gql`` }))
    ).then((responses) => ({ data: responses.map((json: any) => json.id) }));
  },

  create: (resource, params) => {
    return apolloClient
      .mutate({
        mutation: query.create({ resource }),
        variables: { data: params.data },
      })
      .then(({ data }: any) => {
        return {
          data: data?.data,
        };
      });
  },

  delete: (resource, params) => {
    return apolloClient
      .mutate({
        mutation: query.remove({ resource }),
        variables: {
          id: params.id,
        },
      })
      .then(({ data }: any) => {
        return {
          data: data,
        };
      });
  },
  deleteMany: (resource, params) => {
    return apolloClient
      .mutate({
        mutation: query.removeMany({ resource }),
        variables: params,
      })
      .then(({ data }: any) => {
        return {
          data: [],
        };
      });
  },
});

export default apiService;
