import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from "react-admin";

const UserEdit = ({ ...props }) => (
  <Edit title={"user.editTitle"} {...props}>
    <SimpleForm>
      <TextInput label={"general.id"} source="id" />
      <TextInput validate={[required()]} label={"user.login"} source="login" />
      <TextInput validate={[required()]} label={"user.email"} source="email" />
      <TextInput validate={[required()]} label={"user.name"} source="name" />
      <TextInput label={"user.password"} source="password" />
      <ReferenceArrayInput validate={[required()]} source="roleIds" reference="Role">
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
