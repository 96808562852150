const messages = {
  general: {
    id: "Id",
    name: "Názov",
    title: "Nadpis",
    code: "Kľúč",
    value: "Hodnota",
    component: "Komponent",
    createdAt: "Vytvorený",
    key: "Kľúč",
    url: "Url",
    updatedAt: "Upravený",
    email: "E-mail",
    properties: "Parametre",
    category: "Kategória",
    components: "Komponenty",
    slug: "Kľúč",
    sections: "Sekcie",
    summary: "Všeobecné",
    type: "Typ",
    section: "Sekcia",
    ordering: "Poradie",
    dropzone:
      "Súbory nahráte kliknutím alebo potiahnutím do zóny. Povolené súbory (JPEG, PNG, WEBP, GIF, PDF)",
    button: {
      editSection: "Upraviť sekciu",
      addSection: "Pridať sekciu",
      addComponent: "Pridať komponent",
      editProp: "Upraviť",
      addProp: "Pridať parameter",
      settings: "Konfigurácia",
    },
  },
  page: {
    listTitle: "Stránky",
    editTitle: "Upraviť stránku",
    createTitle: "Vytvoriť stránku",
    sectionTitle: "Názov sekcie",
    showTitle: "Konfigurácia stránky",
    sectionCode: "Kľúč sekcie",
    category: "Kategória",
    published: "Zverejnené",
    slug: "URL",
    title: "Názov",
    description: "Popis",
    content: "Text",
    addSection: "Pridať sekciu",
    addProperty: "Pridať konfiguráciu",
    button: {
      editPageSection: "Upraviť väzbu",
    },
  },
  section: {
    listTitle: "Sekcie",
    editTitle: "Upraviť sekciu",
    createTitle: "Vytvoriť sekciu",
    showTitle: "Konfigurácia sekcie",
    button: {
      addNewProp: "Pridať parameter",
      addNewChildren: "Pridať komponent",
      editProp: "Upraviť",
      editChildren: "Upraviť",
    },
  },
  tag: {
    listTitle: "Tagy",
    editTitle: "Upraviť tag",
    createTitle: "Vytvoriť tag",
  },
  article: {
    listTitle: "Články",
    editTitle: "Upraviť kategóriu",
    showTitle: "Konfigurácia článku",
    createTitle: "Vytvoriť kategóriu",
    button: {
      addProp: "Pridať parameter",
      editProp: "Upraviť",
    },
  },
  component: {
    listTitle: "Komponenty",
    editTitle: "Upraviť komponent",
    showTitle: "Konfigurácia komponentu",
    createTitle: "Vytvoriť komponent",
    button: {
      addProp: "Pridať parameter",
      editProp: "Upraviť",
    },
  },
  user: {
    listTitle: "Používatelia",
    createTitle: "Nový používateľ",
    editTitle: "Upraviť používateľa",
    login: "Login",
    email: "E-mail",
    name: "Meno",
    password: "Heslo",
  },
  file: {
    listTitle: "Súbory",
    createTitle: "Nahrať súbor",
    name: "Názov súboru",
    mimetype: "Typ",
    path: "Link",
  },
  gallery: {
    listTitle: "Galérie",
    createTitle: "Nová galéria",
    showTitle: "Konfigurácia galérie",
    editTitle: "Upraviť galériu",
    button: {
      addProp: "Pridať parameter",
    },
  },
  property: {
    listTitle: "Parametre",
    createTitle: "Nový parameter",
    editTitle: "Upraviť parameter",
    relation: "Konfigurácia",
  },
  fbFeed: {
    listTitle: "FB Feed",
  },
  formData: {
    editTitle: "Upraviť správu",
    listTitle: "Formuláre",
    editForm: "Upraviť formulár",
    message: "Správa",
    gdprApproval: "Súhlas so spracovaním os. údajov",
    telephone: "Telefón",
    city: "Obec",
    street: "Adresa",
    zip: "ZIP",
  },
  global: {
    listTitle: "Nastavenia",
    showTitle: "Konfigurácia globálnych premenných",
    createTitle: "Nové nastavenia",
    editTitle: "Upraviť nastavenia",
    properties: "Parametre",
    dialog: {
      addSection: {
        title: "Pridať sekciu",
      },
      addComponent: {
        title: "Pridať komponent",
      },
    },
    button: {
      removeSection: "Odobrať sekciu",
      removeComponent: "Odobrať komponent",
      addProp: "Pridať parameter",
      editProp: "Upraviť",
    },
  },
  advertisement: {
    listTitle: "Inzeráty",
    createTitle: "Nový inzerát",
    showTitle: "Konfigurácia inzerátu",
    editTitle: "Upraviť inzerát",
    button: {
      addProp: "Pridať parameter",
      editProp: "Upraviť",
    },
  },
  role: {
    listTitle: "Skupiny",
    createRole: "Nová rola",
    editTitle: "Upraviť rolu",
  },
  category: {
    listTitle: "Kategórie",
    createCategory: "Nová katagória",
    editCategory: "Upraviť kategóriu",
  },
};

export default messages;
