import SectionCreate from "./section.create";
import SectionList from "./section.list";
import SectionEdit from "./section.edit";
import SectionShow from "./section.show";

export default {
  create: SectionCreate,
  list: SectionList,
  edit: SectionEdit,
  show: SectionShow,
};
