import { Box } from "@material-ui/core";
import {
  AspectRatioOutlined,
  ClearAllOutlined,
  GroupWorkOutlined,
} from "@material-ui/icons";
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  Tab,
  ChipField,
} from "react-admin";
import { ShowActions } from "../../components/ra/Actions";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import AddPropertyButton from "./_components/AddPropertyButton";
import DeletePropertyButton from "./_components/DeletePropertyButton";
import EditPropertyButton from "./_components/EditPropertyButton";
import QuickAddComponentButton from "./_components/QuickAddComponentButton";
import QuickAddSectionButton from "./_components/QuickAddSectionButton";
import RemoveComponentButton from "./_components/RemoveComponentButton";
import RemoveSectionButton from "./_components/RemoveSectionButton";

const GlobalShow = ({ ...props }) => (
  <Show actions={<ShowActions />} title={"global.showTitle"} {...props}>
    <TabbedShowLayout>
      <Tab icon={<ClearAllOutlined />} label="general.properties">
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference="Property"
          target="globalId"
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <Datagrid>
            <ChipField source="name" label="general.code" />
            <TextField source="title" label="general.name" />
            <TextField source="type" label="general.type" />
            <PropertyValueField source="value" label="general.value" />
            <EditPropertyButton />
            <DeletePropertyButton />
          </Datagrid>
        </ReferenceManyField>
        <Box height={10} />
        <AddPropertyButton />
        <Box height={10} />
      </Tab>
      <Tab
        path="sections"
        icon={<AspectRatioOutlined />}
        label="general.sections"
      >
        <ReferenceManyField
          fullWidth
          addLabel={false}
          reference="Section"
          target="globalId"
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <Datagrid>
            <ChipField source="name" label="general.code" />
            <TextField source="title" label="general.name" />
            <RemoveSectionButton />
          </Datagrid>
        </ReferenceManyField>
        <Box height={10} />
        <QuickAddSectionButton />
        <Box height={10} />
      </Tab>
      <Tab
        path="components"
        icon={<GroupWorkOutlined />}
        label="general.components"
      >
        <ReferenceManyField
          fullWidth
          addLabel={false}
          reference="Component"
          target="globalId"
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <Datagrid>
            <ChipField source="name" label="general.code" />
            <TextField source="title" label="general.name" />
            <RemoveComponentButton />
          </Datagrid>
        </ReferenceManyField>
        <Box height={10} />
        <QuickAddComponentButton />
        <Box height={10} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default GlobalShow;
