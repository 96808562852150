import { withStyles } from "@material-ui/core/styles";
import { ShowButton } from "react-admin";
import { SettingsOutlined } from "@material-ui/icons";

const styles = {
  button: {},
};

const SettingsButton = ({ classes, record, ...props }: any) => (
  <ShowButton
    {...props}
    label="general.button.settings"
    icon={<SettingsOutlined />}
    record={record}
  />
);

export default withStyles(styles)(SettingsButton);
