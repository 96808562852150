import {
  Datagrid,
  TextField,
  ReferenceManyField,
  NumberField,
  ChipField,
  SimpleShowLayout,
  Show,
} from "react-admin";
import { ShowActions } from "../../components/ra/Actions";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import AddPropertyButton from "./_components/AddPropertyButton";
import EditPropertyButton from "./_components/EditPropertyButton";

const ComponentShow = ({ ...props }) => (
  <Show actions={<ShowActions/>} title={"component.showTitle"} {...props}>
    <SimpleShowLayout>
      <ReferenceManyField
        addLabel={false}
        reference="Property"
        target="componentId"
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid>
          <ChipField source="name" label="general.code" />
          <TextField source="title" label="general.name" />
          <PropertyValueField source="value" label="general.value" />
          <NumberField source="ordering" label="general.ordering" />
          <EditPropertyButton />
        </Datagrid>
      </ReferenceManyField>
      <AddPropertyButton />
    </SimpleShowLayout>
  </Show>
);

export default ComponentShow;
