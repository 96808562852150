import { Create, required, SimpleForm, TextInput } from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

const RoleCreate = ({ onCancel, ...props }: any) => (
  <Create actions={<CreateActions />} title={"role.createTitle"} {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={[required()]} label={"general.name"} source="name" />
      <TextInput
        validate={[required()]}
        label={"general.title"}
        source="title"
      />
    </SimpleForm>
  </Create>
);

export default RoleCreate;
