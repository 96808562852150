import GlobalCreate from "./global.create";
import GlobalList from "./global.list";
import GlobalEdit from "./global.edit";
import GlobalShow from "./global.show";

export default {
  create: GlobalCreate,
  list: GlobalList,
  edit: GlobalEdit,
  show: GlobalShow,
};
