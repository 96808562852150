import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { Add, Edit } from "@material-ui/icons";

const styles = {
  button: {
    // marginTop: "1em",
  },
};

const EditPropertyButton = ({ classes, record }: any) => (
  <Button
    className={classes.button}
    variant="outlined"
    component={Link}
    to={`/property/${record.id}/edit?global_id=${record.globalId}&redirect_to=global`}
    label="global.button.editProp"
    title="global.button.editProp"
  >
    <Edit />
  </Button>
);

export default withStyles(styles)(EditPropertyButton);
