import { parse } from "query-string";
import {
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

const ComponentCreate = ({ onCancel, ...props }: any) => {
  const {
    parent_id: parent_id_string,
    redirect_to,
    template,
  }: any = parse(props.location.search);

  const parent_id = parent_id_string ? parseInt(parent_id_string, 10) : "";

  // Redirect to Section
  const redirect = parent_id ? `/${redirect_to}/${parent_id}/show/components` : "list";

  return (
    <Create
      actions={<CreateActions />}
      title={"component.createTitle"}
      {...props}
    >
      <SimpleForm
        redirect={redirect}
        defaultValue={{ type: "component", parentId: parent_id, template }}
      >
        <SelectInput
          initialValue=""
          source="template"
          validate={[required()]}
          choices={[
            { id: "header-slider", name: "Slider - Domovská stránka" },
            { id: "contact", name: "Kontakt" },
            { id: "certificate", name: "Certifikát" },
            { id: "gallery-tag", name: "Kategória v galérii (filter)" },
            { id: "navigation", name: "Navigácia" },
            { id: "bonus-card-item", name: "Výhody bonusovej karty"}
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => [
            formData.template === "header-slider" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="header-slider"
                source="name"
                label="general.code"
              />
            ),
            formData.template === "contact" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="contact"
                source="name"
                label="general.code"
              />
            ),
            formData.template === "certificate" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="certificate"
                source="name"
                label="general.code"
              />
            ),
            formData.template === "gallery-tag" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="gallery-tag"
                source="name"
                label="general.code"
              />
            ),
            formData.template === "navigation" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="navigation"
                source="name"
                label="general.code"
              />
            ),
            formData.template === "bonus-card-item" && (
              <TextInput
                disabled
                style={{ width: 255 }}
                initialValue="bonus-card-item"
                source="name"
                label="general.code"
              />
            ),
          ]}
        </FormDataConsumer>
        <TextInput
          label={"general.name"}
          validate={[required()]}
          source="title"
        />
        <ReferenceInput
          label="general.section"
          source="parentId"
          reference="Section"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput label={"general.ordering"} source="ordering" />
      </SimpleForm>
    </Create>
  );
};

export default ComponentCreate;
