import { Box, Dialog, makeStyles } from "@material-ui/core";
import { LinkOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { FieldProps, useRecordContext } from "react-admin";
import useConfig from "../../../hooks/useConfig";
import fileIcon from "../../../resource/universal-file-icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootRounded: () => ({
    height: 30,
    width: 30,
    marginRight: 2,
    marginBottom: 2,
    cursor: "pointer",
    objectFit: "cover",
    borderRadius: "90%",
    overflow: "hidden",
  }),
  rootSquare: () => ({
    height: 25,
    width: "auto",
    borderRadius: 0,
    objectFit: "cover",
    overflow: "hidden",
  }),
  loading: {
    color: theme.palette.secondary.main,
    margin: "auto",
  },
  previewImage: {},
}));

const ImagePreview = ({
  preview,
  onClose,
}: {
  preview: string;
  onClose: () => any;
}) => {
  const config = useConfig();
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={true}>
      <img
        alt="preview"
        className={classes.previewImage}
        src={config.FILE_SERVICE + "/" + preview}
      />
    </Dialog>
  );
};

const Media = ({ value }: { value: string }) => {
  const classes = useStyles();
  const config = useConfig();
  const [media, setMedia] = useState([]);
  const [preview, setPreview] = useState();

  useEffect(() => {
    try {
      const media = JSON.parse(value);
      setMedia(media);
    } catch (e) {
      console.log(value);
      console.error("Media parse error", e);
    }
  }, [value]);

  return (
    <>
      <Box flexWrap="wrap" display="flex">
        {media?.map((m: any, idx) => (
          <span key={idx} className={classes.root}>
            <img
              onClick={() => setPreview(m?.path)}
              className={classes.rootRounded}
              src={
                m?.mimetype === "application/pdf"
                  ? fileIcon
                  : config.FILE_SERVICE + "/" + m?.path
              }
              alt="preview"
            />
          </span>
        ))}
      </Box>
      {preview && (
        <ImagePreview onClose={() => setPreview(undefined)} preview={preview} />
      )}
    </>
  );
};

const PropertyValueField = ({ ...rest }: FieldProps) => {
  const record = useRecordContext(rest);

  if (
    (record.value && record.type === "file") ||
    record.type === "file_multiple"
  ) {
    return <Media value={record.value} />;
  }
  if ((record.value && record.type === "video") || record.type === "link") {
    return (
      <Box display="flex" alignItems="center">
        <a
          rel="noreferrer"
          href={record.value}
          style={{ display: "flex" }}
          target="_blank"
        >
          <LinkOutlined />
        </a>
      </Box>
    );
  }
  return <span>{record.value}</span>;
};

export default PropertyValueField;
