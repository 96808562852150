import {
  List,
  TextField,
  EditButton,
  Responsive,
  DateField,
  BooleanField,
} from "react-admin";
import Datagrid from "../../components/ra/Datagrid";
import ColorTextField from "../../components/ra/fields/ColorTextField";
import SettingsButton from "../../components/ra/SettingsButton";

export const ArticleList = ({ ...props }) => (
  <List
    bulkActionButtons={false}
    title={"article.listTitle"}
    sort={{ field: "id", order: "desc" }}
    // filterDefaultValues={{ email: "" }}
    {...props}
  >
    <Responsive
      small={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ColorTextField label="general.url" source="slug" />
          <TextField label={"page.title"} source="title" />
          <TextField label={"page.description"} source="description" />
          <BooleanField label={"page.published"} source="published" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <SettingsButton />
          <EditButton />
        </Datagrid>
      }
      medium={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ColorTextField label="general.url" source="slug" />
          <TextField label={"page.title"} source="title" />
          <TextField label={"page.description"} source="description" />
          <BooleanField label={"page.published"} source="published" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <SettingsButton />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default ArticleList;
