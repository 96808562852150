import { Create, required, SimpleForm, TextInput } from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

export const UserCreate = ({ ...props }) => (
  <Create actions={<CreateActions />} title={"user.createTitle"} {...props}>
    <SimpleForm redirect="edit">
      <TextInput validate={[required()]} label={"user.login"} source="login" />
      <TextInput validate={[required()]} label={"user.email"} source="email" />
      <TextInput validate={[required()]} label={"user.name"} source="name" />
      <TextInput
        validate={[required()]}
        label={"user.password"}
        source="password"
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
