import PageCreate from "./page.create";
import PageList from "./page.list";
import PageEdit from "./page.edit";
import PageShow from "./page.show";

export default {
  create: PageCreate,
  list: PageList,
  edit: PageEdit,
  show: PageShow,
};
