import {
  Datagrid,
  DateField,
  TextField,
  EditButton,
  List,
  Responsive,
  ChipField,
  SelectInput,
  NumberField,
} from "react-admin";
import SettingsButton from "../../components/ra/SettingsButton";

const postFilters = [
  <SelectInput
    alwaysOn
    label="general.code"
    choices={[
      { id: "header-slider", name: "Slider - Domovská stránka" },
      { id: "certificate", name: "Certifikát" },
      { id: "contact", name: "Kontakt" },
      { id: "gallery-tag", name: "Kategória v galérii (filter)" },
      { id: "navigation", name: "Navigácia" },
      { id: "bonus-card-item", name: "Výhody bonusovej karty"}
    ]}
    source="name"
  />,
];

const ComponentList = ({ ...props }) => {
  return (
    <List filters={postFilters} title={"component.listTitle"} {...props}>
      <Responsive
        small={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <ChipField label={"general.code"} source="name" />
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            <NumberField label={"general.ordering"} source="ordering" />
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
        medium={
          <Datagrid>
            <TextField label={"general.id"} source="id" />
            <ChipField label={"general.code"} source="name" />
            <TextField label={"general.name"} source="title" />
            <DateField label={"general.createdAt"} source="createdAt" />
            <NumberField label={"general.ordering"} source="ordering" />
            <SettingsButton />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default ComponentList;
