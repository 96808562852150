import ReactDropzone from "react-dropzone";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import useConfig from "../../../hooks/useConfig";
import { Button, useTranslate } from "react-admin";
import fileIcon from "../../../resource/universal-file-icon.png";

const IMAGE_PREVIEW_SIZE = 150;
const MAX_SIZE = 20971520; // 20MB

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    maxHeight: 250,
    height: "100%",
    objectFit: "cover",
    textAlign: "center",
    border: 0,
  },
  mediaBox: {
    backgroundColor: theme.palette.grey[300],
  },
  cardPaper: {
    height: 350,
  },
  cardWrapper: {
    height: "100%",
    maxHeight: 350,
  },
  dropZone: {
    borderWidth: 4,
    borderColor: theme.palette.secondary.main,
    borderStyle: "dashed",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  cardAssignedHeader: {
    padding: 4,
    height: 50,
  },
  cardAssignedHeaderTitle: {
    fontSize: 12,
    textAlign: "center",
  },
}));

interface Props {
  onDeleteClick: (file: any) => void;
  onDrop: (files: any) => void;
  onAttachmentClick: (file: any) => void;
  files: Array<any>;
  accept?: string[];
  multiple?: boolean;
}

const Dropzone = ({
  onDeleteClick,
  onAttachmentClick,
  files = [],
  onDrop,
  multiple,
  ...rest
}: Props) => {
  const classes = useStyles();
  const t = useTranslate();
  const config = useConfig();
  return (
    <>
      <ReactDropzone
        maxSize={MAX_SIZE}
        onDrop={onDrop}
        multiple={multiple}
        {...rest}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className={classes.dropZone} {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography align="center">{t("general.dropzone")}</Typography>
            </div>
          </section>
        )}
      </ReactDropzone>
      {files.length > 0 ? (
        <Box p={2} className={classes.mediaBox}>
          <Grid spacing={2} container>
            {files?.map((file) => (
              <Grid item lg={3} md={3} sm={3} xs={1}>
                <div className={classes.cardWrapper}>
                  <Card classes={{ root: classes.cardPaper }}>
                    <CardHeader
                      classes={{
                        root: classes.cardAssignedHeader,
                        title: classes.cardAssignedHeaderTitle,
                      }}
                      title={file.filename}
                    />
                    <CardMedia
                      // component={
                      //   media.resource_type === "online" ? "iframe" : "img"
                      // }
                      component="img"
                      className={classes.cardMedia}
                      image={
                        file?.mimetype === "application/pdf"
                          ? fileIcon
                          : `${config.FILE_SERVICE}/${file.path}`
                      }
                    />
                    <CardActions style={{ float: "right", padding: 3 }}>
                      <Button
                        onClick={() => onDeleteClick(file)}
                        label="ZMAZAŤ"
                      />
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default Dropzone;
