import { makeStyles } from "@material-ui/core";

const LOGO_URL_SMALL = "./logo-small.png";
const LOGO_URL = "./logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: ({ simple }: any) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(simple ? 0 : 2),
    marginTop: theme.spacing(simple ? 1 : 2),
    maxHeight: simple ? 30 : 75,
  }),
}));

type Props = {
  simple?: boolean;
};

const Logo = ({ simple }: Props) => {
  const classes = useStyles({ simple });

  const logoUrl = simple ? LOGO_URL_SMALL : LOGO_URL;

  return (
    <span className={classes.root}>
      <img className={classes.image} src={logoUrl} alt="Logo" />
    </span>
  );
};

export default Logo;
