import {
  CreateActionsProps,
  EditActionsProps,
  ListButton,
  ShowActionsProps,
  TopToolbar,
} from "react-admin";
import SettingsButton from "./SettingsButton";

export const CreateActions = ({ basePath, resource }: CreateActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

export const ShowActions = ({
  // permissions,
  basePath,
  data,
  resource,
}: ShowActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    {/* {permissions === "admin" && (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    )} */}
  </TopToolbar>
);

export const EditActions = ({
  // permissions,
  basePath,
  data,
  configuration,
  resource,
}: EditActionsProps & { configuration?: boolean }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    {configuration && <SettingsButton basePath={basePath} record={data} />}
  </TopToolbar>
);
