import ArticleCreate from "./article.create";
import ArticleList from "./article.list";
import ArticleEdit from "./article.edit";
import ArticleShow from "./article.show";

export default {
  create: ArticleCreate,
  list: ArticleList,
  edit: ArticleEdit,
  show: ArticleShow,
};
