import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CreateActions } from "../../components/ra/Actions";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";

const PageCreate = ({ onCancel, ...props }: any) => (
  <Create actions={<CreateActions />} title={"page.createTitle"} {...props}>
    <SimpleForm redirect="list" defaultValue={{ type: "page" }}>
      <NumberInput label={"general.ordering"} source="ordering" />
      <BooleanInput label={"page.published"} source="published" />
      <ReferenceInput
        validate={[required()]}
        label="page.category"
        source="categoryId"
        reference="Category"
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      {/* <ReferenceArrayInput source="tagIds" reference="Tag">
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput> */}
      <TextInput label={"page.slug"} source="slug" />
      <TextInput label={"page.title"} validate={[required()]} source="title" />
      <TextInput fullWidth label={"page.description"} source="description" />
      <RichTextEditor label={"page.content"} source="content" />
    </SimpleForm>
  </Create>
);

export default PageCreate;
