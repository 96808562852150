import { MenuItemLink, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  icon: {},
}));

const CustomMenuItemLink = (props: any) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <MenuItemLink
      classes={classes}
      {...props}
      primaryText={t(props.primaryText)}
    />
  );
};

export default CustomMenuItemLink;
