import { AuthProvider } from "react-admin";
import config from "../config/config";

const authProvider: AuthProvider = {
  login: ({ username, password }: any) => {
    const mutation = `mutation login($login: String!, $password: String!) {
      auth: login(login: $login, password: $password) {
        user {
            id
            email
            login
            name
            roles {
              name
              title
            }
        }
        token
      }
    }`;

    const cql = {
      operationName: "login",
      query: mutation,
      variables: { login: username, password: password },
    };

    const request = new Request(config.URL_API, {
      method: "POST",
      body: JSON.stringify(cql),
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(({ data, errors }) => {
        if (errors && errors.length && errors[0].message) {
          throw new Error(errors[0].message);
        }

        if (data) {
          localStorage.setItem("user", JSON.stringify(data.auth.user));
        }
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  checkAuth: () => {
    const userString = localStorage.getItem("user");
    if (!userString) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const userString = localStorage.getItem("user");

    if (!userString) {
      return Promise.reject();
    }

    try {
      const user = JSON.parse(userString);
      return Promise.resolve(user?.roles?.map((r: any) => r.name) || []);
    } catch (err) {
      console.error(err);
    }

    return Promise.reject([]);
  },
  logout: function (params: any): Promise<string | false | void> {
    const mutation = `mutation logout { logout }`;

    const cql = {
      operationName: "logout",
      query: mutation,
    };

    const request = new Request(config.URL_API, {
      method: "POST",
      body: JSON.stringify(cql),
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    fetch(request);

    localStorage.removeItem("user");
    return Promise.resolve();
  },
  checkError: function (error: any): Promise<void> {
    const status = error.status;
    if (status === 401 || status === 403) {
      // localStorage.removeItem("user");
      return Promise.reject();
      // return Promise.reject({ redirectTo: '/login' });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
    // return Promise.reject({ redirectTo: "/login" });
  },
};

export default authProvider;
