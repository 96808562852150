import {
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  List,
  NumberField,
  Responsive,
  TextField,
  TextInput,
} from "react-admin";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";

const propertyFilter = [
  <TextInput alwaysOn label="general.code" source="name" />,
  <TextInput alwaysOn label="general.name" source="title" />,
];

const PropertyList = ({ ...props }) => (
  <List
    filters={propertyFilter}
    bulkActionButtons={false}
    title={"property.listTitle"}
    sort={{ field: "id", order: "desc" }}
    {...props}
  >
    <Responsive
      small={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ChipField label={"general.code"} source="name" />
          <TextField label={"general.name"} source="title" />
          <TextField label={"general.type"} source="type" />
          <PropertyValueField label={"general.value"} source="value" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <EditButton />
        </Datagrid>
      }
      medium={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ChipField label={"general.code"} source="name" />
          <TextField label={"general.name"} source="title" />
          <TextField label={"general.type"} source="type" />
          <PropertyValueField label={"general.value"} source="value" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <NumberField label={"general.ordering"} source="ordering" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default PropertyList;
