import {
  Datagrid,
  DateField,
  TextField,
  EditButton,
  List,
  Responsive,
  BooleanField,
  NumberField,
} from "react-admin";
import ColorTextField from "../../components/ra/fields/ColorTextField";
import SettingsButton from "../../components/ra/SettingsButton";

export const AdvertisementList = ({ ...props }) => (
  <List
    sort={{ field: "id", order: "desc" }}
    title={"advertisement.listTitle"}
    {...props}
  >
    <Responsive
      small={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ColorTextField label="general.url" source="slug" />
          <TextField label={"page.title"} source="title" />
          <TextField label={"page.description"} source="description" />
          <BooleanField label={"page.published"} source="published" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <NumberField label={"general.ordering"} source="ordering" />
          <SettingsButton />
          <EditButton />
        </Datagrid>
      }
      medium={
        <Datagrid>
          <TextField label={"general.id"} source="id" />
          <ColorTextField label="general.url" source="slug" />
          <TextField label={"page.title"} source="title" />
          <TextField label={"page.description"} source="description" />
          <BooleanField label={"page.published"} source="published" />
          <DateField label={"general.createdAt"} source="createdAt" />
          <NumberField label={"general.ordering"} source="ordering" />
          <SettingsButton />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default AdvertisementList;
