import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { Add } from "@material-ui/icons";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddPropertyButton = ({ classes, record }: any) => (
  <Button
    className={classes.button}
    variant="outlined"
    component={Link}
    to={`/property/create?global_id=${record.id}&redirect_to=global`}
    label="global.button.addProp"
    title="global.button.addProp"
  >
    <Add />
  </Button>
);

export default withStyles(styles)(AddPropertyButton);
