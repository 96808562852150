import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#444ca0",
      dark: "#012471",
      light: "#7678d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#444ca0",
      dark: "#012471",
      light: "#7678d2",
      contrastText: "#fff",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiPaper: {
      // Name of the rule
      root: {
        // Some CSS
        // backgroundColor: 'white',
      },
    },
  },
}) as any;

export default theme;
