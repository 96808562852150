import dataProvider from "./base/api-provider";
import skMessages from "./i18n/sk";
import enMessages from "./i18n/en";
import theme from "./config/theme";
import BaseLayout from "./components/layout/BaseLayout";
import routes from "./config/routes";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import slovakMessages from "ra-language-slovak";
import { Admin, Resource } from "react-admin";
import user from "./pages/user";
import apolloClient from "./base/apollo-client";
import authClient from "./base/auth-client";
import article from "./pages/article";
import section from "./pages/section";
import component from "./pages/components";
import { FbFeeedList } from "./pages/fbFeed";
import property from "./pages/property";
import file from "./pages/file";
import gallery from "./pages/gallery";
import tag from "./pages/tags";
import postSection from "./pages/post-section";
import page from "./pages/page";
import formData from "./pages/form-data";
import role from "./pages/role";
import category from "./pages/category";
import global from "./pages/global";
import advertisement from "./pages/advertisement";

const messages: any = {
  sk: { ...slovakMessages, ...skMessages },
  en: { ...englishMessages, ...enMessages },
};

const i18nProvider = polyglotI18nProvider(
  (locale: any) => messages[locale],
  "sk"
);

const App = () => {
  // const config = useConfig();

  return (
    <Admin
      customRoutes={routes}
      i18nProvider={i18nProvider}
      layout={BaseLayout}
      theme={theme}
      authProvider={authClient}
      dataProvider={dataProvider(apolloClient)}
    >
      <Resource {...user} name="User" />
      {/* <Resource list={FbFeeedList} name="FbFeed" /> */}
      <Resource {...article} name="Article" />
      <Resource {...page} name="Page" />
      <Resource {...advertisement} name="Advertisement" />
      <Resource {...file} name="File" />
      <Resource {...gallery} name="Gallery" />
      <Resource {...postSection} name="PostSection" />
      <Resource {...section} name="Section" />
      <Resource {...tag} name="Tag" />
      <Resource {...component} name="Component" />
      <Resource {...property} name="Property" />
      <Resource {...postSection} name="PostSection" />
      <Resource {...formData} name="FormData" />
      <Resource {...global} name="Global" />
      <Resource {...role} name="Role" />
      <Resource {...category} name="Category" />
    </Admin>
  );
};

export default App;
