import { useInput } from "react-admin";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import useFileStorage from "../../../hooks/useFileStorage";
import useConfig from "../../../hooks/useConfig";

const BoundedRichTextEditor = (props: any) => {
  const { processUpload } = useFileStorage();
  const {
    input: { name, onChange, value, ...rest },
  } = useInput(props);

  let newEditorState;
  try {
    const contentState = convertFromRaw(JSON.parse(value));
    newEditorState = EditorState.createWithContent(contentState);
  } catch (ex) {
    newEditorState = EditorState.createEmpty();
  }
  const config = useConfig();

  const [state, setState] = useState({
    editorState: newEditorState,
  });

  const onEditorStateChange = (editorState: any) => {
    setState({
      editorState,
    });
  };

  const { editorState } = state;

  useEffect(() => {
    editorState &&
      onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  }, [editorState, onChange]);

  const uploadCallback = async (file: File) => {
    const data = await processUpload(file);
    return {
      data: { link: config.FILE_SERVICE + "/" + data.path },
    };
  };

  return (
    <div>
      <Editor
        editorStyle={{ minHeight: 200 }}
        editorState={editorState}
        wrapperClassName="richtext-editor-wrapper"
        editorClassName="richtext-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          inline: { inDropdown: false },
          list: { inDropdown: true },
          textAlign: { inDropdown: false },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadCallback,
            alt: { present: true, mandatory: true },
          },
          options: [
            "inline",
            "blockType",
            "fontSize",
            // "fontFamily",
            "list",
            "textAlign",
            // "colorPicker",
            "link",
            "embedded",
            // "embedded" /*, 'emoji'*/,
            "image",
            // "remove",
            // "history",
          ],
        }}
      />
      {/* <textarea
        style={{ width: "100%", height: 400 }}
        disabled
        value={JSON.stringify(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
};

const RichTextEditor = (props: any) => {
  const { source, ...rest } = props;

  return (
    <span>
      <BoundedRichTextEditor source={source} {...rest} />
    </span>
  );
};

export default RichTextEditor;
