import { SaveButton, Toolbar, ToolbarProps } from "react-admin";

export const EditToolbar = ({
  hasDelete,
  ...props
}: ToolbarProps & { hasDelete?: boolean }) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};
