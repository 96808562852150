import {
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { parse } from "query-string";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";
import FileInput from "../../components/ra/inputs/FileInput";
import { CreateActions } from "../../components/ra/Actions";

const PropertyCreate = ({ ...props }) => {
  const {
    component_id: component_id_string,
    post_id: post_id_string,
    global_id: global_id_string,
    redirect_to,
  }: any = parse(props.location.search);

  const component_id = component_id_string
    ? parseInt(component_id_string, 10)
    : "";

  const post_id = post_id_string ? parseInt(post_id_string, 10) : "";
  const global_id = global_id_string ? parseInt(global_id_string, 10) : "";

  let redirect: string | boolean = false;

  if (component_id) redirect = `/${redirect_to}/${component_id}/show`;
  if (post_id) redirect = `/${redirect_to}/${post_id}/show`;
  if (global_id) redirect = `/${redirect_to}/${global_id}/show`;

  let defaultValue: any = {};
  if (component_id) {
    defaultValue.componentId = component_id;
  } else if (post_id) {
    defaultValue.postId = post_id;
  } else if (global_id) {
    defaultValue.globalId = global_id;
  }

  return (
    <Create title={"property.createTitle"} {...props}>
      <SimpleForm defaultValue={defaultValue} redirect={redirect}>
        <NumberInput label={"general.ordering"} source="ordering" />
        <TextInput
          validate={[required()]}
          label={"general.code"}
          source="name"
        />
        <TextInput
          validate={[required()]}
          label={"general.name"}
          source="title"
        />
        <SelectInput
          defaultValue={"text"}
          validate={[required()]}
          label="Typ hodnoty"
          source="type"
          choices={[
            { id: "boolean", name: "Áno / Nie" },
            { id: "text", name: "Text" },
            { id: "longtext", name: "Long Text" },
            // { id: "richtext", name: "Editor" },
            { id: "number", name: "Číslo" },
            { id: "link", name: "Link" },
            { id: "video", name: "Video (link)" },
            { id: "file", name: "Samostatný obrázok" },
            { id: "file_multiple", name: "Viac obrázkov" },
            { id: "date", name: "Dátum" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => [
            formData.type === "boolean" && (
              <BooleanInput validate={[required()]} source="value" label="general.value" />
            ),
            formData.type === "text" && (
              <TextInput fullWidth validate={[required()]} source="value" label="general.value" />
            ),
            formData.type === "link" && (
              <TextInput fullWidth validate={[required()]} source="value" label="general.value" />
            ),
            formData.type === "video" && (
              <TextInput fullWidth validate={[required()]} source="value" label="general.value" />
            ),
            formData.type === "longtext" && (
              <TextInput
                multiline
                fullWidth
                minRows={3}
                maxRows={10}
                validate={[required()]}
                source="value"
                label="general.value"
              />
            ),
            formData.type === "richtext" && (
              <RichTextEditor validate={[required()]} label="general.value" source="value" />
            ),
            formData.type === "number" && (
              <NumberInput validate={[required()]} fullWidth label="general.value" source="value" />
            ),
            formData.type === "file" && (
              <FileInput
                {...rest}
                validate={[required()]}
                label="general.value"
                multiple={false}
                source="value"
              />
            ),
            formData.type === "file_multiple" && (
              <FileInput
                {...rest}
                validate={[required()]}
                label="general.value"
                multiple={true}
                source="value"
              />
            ),
            formData.type === "date" && (
              <DateInput fullWidth validate={[required()]} label="general.value" source="value" />
            ),
          ]}
        </FormDataConsumer>
        {/* <ReferenceInput
          label="Component"
          source="componentId"
          reference="Component"
        >
          <SelectInput optionText="title" />
        </ReferenceInput> */}
      </SimpleForm>
    </Create>
  );
};

export default PropertyCreate;
