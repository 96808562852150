import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
} from "react-admin";
import { parse } from "query-string";

const PostSectionEdit = ({ ...props }) => {
  let defaultValue: any = {};

  const {
    component_id: component_id_string,
    post_id: post_id_string,
    redirect_to,
  }: any = parse(props.location.search);

  const component_id = component_id_string
    ? parseInt(component_id_string, 10)
    : "";

  const post_id = post_id_string ? parseInt(post_id_string, 10) : "";

  const redirect = post_id ? `/${redirect_to}/${post_id}/show/sections` : false;

  if (post_id) {
    defaultValue["postId"] = post_id;
  }
  if (component_id) {
    defaultValue["componentId"] = component_id;
  }

  return (
    <Edit title={"postSection.editTitle"} {...props}>
      <SimpleForm defaultValue={defaultValue} redirect={redirect}>
        <ReferenceInput
          label="Section"
          validate={[required()]}
          source="componentId"
          reference="Section"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Page"
          source="postId"
          validate={[required()]}
          reference="Page"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput label={"general.ordering"} source="ordering" />
      </SimpleForm>
    </Edit>
  );
};

export default PostSectionEdit;
