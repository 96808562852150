import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  required,
} from "react-admin";
import { EditActions } from "../../components/ra/Actions";

const GalleryEdit = ({ ...props }) => (
  <Edit
    actions={<EditActions configuration />}
    title={"gallery.editTitle"}
    {...props}
  >
    <SimpleForm defaultValue={{ type: "gallery" }}>
      {/* <NumberInput label={"general.ordering"} source="ordering" /> */}
      {/* <TextInput label={"general.code"} source="name" /> */}
      <TextInput
        validate={[required()]}
        label={"general.name"}
        source="title"
      />
      <ReferenceArrayInput source="tagIds" reference="Tag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default GalleryEdit;
