import ComponentCreate from "./component.create";
import ComponentList from "./component.list";
import ComponentEdit from "./component.edit";
import ComponentShow from "./component.show";

export default {
  create: ComponentCreate,
  list: ComponentList,
  edit: ComponentEdit,
  show: ComponentShow,
};
