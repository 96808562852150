import { Link } from "react-router-dom";
import { SettingsOutlined } from "@material-ui/icons";
import { Button } from "react-admin";

const ShowSectionButton = ({ record }: any) => {
  return (
    <Button
      variant="outlined"
      component={Link}
      to={`/section/${record?.component?.id}/show?post_id=${record?.post?.id}&redirect_to=page`}
      label="general.button.settings"
      title="general.button.settings"
    >
      <SettingsOutlined />
    </Button>
  );
};

export default ShowSectionButton;
