import {
  TextField,
  ReferenceManyField,
  Datagrid,
  ChipField,
  Show,
  SimpleShowLayout,
} from "react-admin";
import PropertyValueField from "../../components/ra/fields/PropertyValueField";
import AddPropertyButton from "./_components/AddPropertyButton";
import EditPropertyButton from "./_components/EditPropertyButton";

const ArticleShow = ({ ...props }) => (
  <Show title={"article.showTitle"} {...props}>
    <SimpleShowLayout>
      <ReferenceManyField
        fullWidth
        // addLabel={false}
        label="property.relation"
        reference="Property"
        target="postId"
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid>
          <ChipField source="name" label="general.code" />
          <TextField source="title" label="general.name" />
          <PropertyValueField source="value" label="general.value" />
          <EditPropertyButton />
        </Datagrid>
      </ReferenceManyField>
      <AddPropertyButton />
    </SimpleShowLayout>
  </Show>
);

export default ArticleShow;
