import { withStyles } from "@material-ui/core/styles";
import { useCallback } from "react";
import {
  useNotify,
  Button,
  useRefresh,
  useTranslate,
  useUpdate,
} from "react-admin";

const styles = {};

const RemoveComponentButton = ({ classes, record, ...rest }: any) => {
  const [update] = useUpdate("Component");
  const refresh = useRefresh();
  const notify = useNotify();
  const t = useTranslate();

  const handleRemoveComponent = useCallback(() => {
    update(
      {
        payload: {
          data: { id: record.id, type: "component", globalId: null },
        },
      },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  }, [notify, record.id, refresh, update]);

  return (
    <Button
      label="global.button.removeComponent"
      onClick={handleRemoveComponent}
      variant="outlined"
    />
  );
};

export default withStyles(styles)(RemoveComponentButton);
