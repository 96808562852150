import { makeStyles } from "@material-ui/core";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}));

const ColorTextField = ({ ...rest }) => {
  const record = useRecordContext(rest);
  const classes = useStyles();
  return <span className={classes.root}>{record.slug}</span>;
};

export default ColorTextField;
