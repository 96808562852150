import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

const GallelyCreate = ({ onCancel, ...props }: any) => (
  <Create actions={<CreateActions />} title={"gallery.createTitle"} {...props}>
    <SimpleForm redirect="list" defaultValue={{ type: "gallery" }}>
      {/* <NumberInput label={"general.ordering"} source="ordering" /> */}
      {/* <TextInput label={"general.code"} source="name" /> */}
      <TextInput
        validate={[required()]}
        label={"general.name"}
        source="title"
      />
      <ReferenceArrayInput source="tagIds" reference="Tag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default GallelyCreate;
