import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { EditActions } from "../../components/ra/Actions";
import { EditToolbar } from "../../components/ra/Toolbars";

const GlobalEdit = ({ ...props }) => (
  <Edit
    actions={<EditActions configuration />}
    title={"global.editTitle"}
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput label={"general.code"} validate={[required()]} source="name" />
      <TextInput
        label={"general.name"}
        validate={[required()]}
        source="title"
      />
    </SimpleForm>
  </Edit>
);

export default GlobalEdit;
