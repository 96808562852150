import { gql } from "@apollo/client";
import queries from "./query-templates";
import pluralize from "pluralize";

type SchemaResource = {
  fields: string;
  getList: any;
  getOne: any;
  getMany: any;
  getManyReference: any;
  update: any;
  create: any;
  updateMany: any;
  remove: any;
  removeMany: any;
};

type Schema = Record<string, SchemaResource>;

const schema: Schema = {
  FbFeed: {
    fields: `
      id
    `,
    ...queries,
  },
  File: {
    fields: `
      id
      filename
      path
      generatedId
      mimetype
      encoding
      createdAt
    `,
    ...queries,
  },
  PostSection: {
    fields: `
      id
      ordering
      component {
        id
        name
        title
      }
      post {
        id
        title
      }
      createdAt
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "PostSectionInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "PostSectionInput!"),
  },
  User: {
    fields: `
      id
      name
      email
      login
      createdAt
      roleIds
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "UserUpdateInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "UserCreateInput!"),
  },
  Article: {
    fields: `
      id
      title
      description
      published
      content
      createdAt
      tagIds
      ordering
      slug
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "PostInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "PostInput!"),
  },
  Page: {
    fields: `
      id
      title
      slug
      categoryId
      description
      published
      content
      createdAt
      tagIds
      ordering
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "PostInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "PostInput!"),
  },
  Advertisement: {
    fields: `
      id
      title
      slug
      categoryId
      description
      published
      content
      createdAt
      ordering
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "PostInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "PostInput!"),
  },
  Category: {
    fields: `
      id
      title
      name
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "CategoryInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "CategoryInput!"),
  },
  Tag: {
    fields: `
      id
      name
      title
      createdAt
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "TagInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "TagInput!"),
  },
  Component: {
    fields: `
      id
      type
      name
      title
      createdAt
      parentId
      ordering
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "ComponentInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "ComponentInput!"),
  },
  Section: {
    fields: `
      id
      type
      name
      title
      createdAt
      globalId
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "SectionUpdateInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "SectionInput!"),
  },
  Gallery: {
    fields: `
      id
      type
      name
      title
      tagIds
      createdAt
      ordering
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "GalleryInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "GalleryInput!"),
  },
  Property: {
    fields: `
      id
      name
      title
      value
      type
      createdAt
      postId
      globalId
      componentId
      ordering
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "PropertyInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "PropertyInput!"),
  },
  FormData: {
    fields: `
      id
      name
      email
      telephone
      city
      street
      zip
      message
      type
      createdAt
      gdprApproval
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "FormDataInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "FormDataInput!"),
    removeMany: (resource: string, fields: string) => {
      return gql`mutation multipleDelete${pluralize.plural(
        resource
      )}($ids: [Int!]!) {
        data: multipleDelete${pluralize.plural(resource)}(ids: $ids) 
      }`;
    },
  },
  Role: {
    fields: `
      id
      title
      name
      createdAt
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "RoleInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "RoleInput!"),
  },
  Global: {
    fields: `
      id
      name
      title
      createdAt
    `,
    ...queries,
    update: (resource: string, fields: string) =>
      queries.update(resource, fields, "GlobalInput!"),
    create: (resource: string, fields: string) =>
      queries.create(resource, fields, "GlobalInput!"),
  },
};

const getList = ({ resource }: { resource: string }) => {
  return schema[resource].getList(resource, schema[resource].fields);
};

const getOne = ({ resource }: { resource: string }) => {
  return schema[resource].getOne(resource, schema[resource].fields);
};

const getMany = ({ resource }: { resource: string }) => {
  return schema[resource].getMany(resource, schema[resource].fields);
};

const getManyReference = ({ resource }: { resource: string }) => {
  return schema[resource].getManyReference(resource, schema[resource].fields);
};

const update = ({ resource }: { resource: string }) => {
  return schema[resource].update(resource, schema[resource].fields);
};

const create = ({ resource }: { resource: string }) => {
  return schema[resource].create(resource, schema[resource].fields);
};

const updateMany = ({ resource }: { resource: string }) => {
  return schema[resource].updateMany(resource, schema[resource].fields);
};

const remove = ({ resource }: { resource: string }) => {
  return schema[resource].remove(resource, schema[resource].fields);
};

const removeMany = ({ resource }: { resource: string }) => {
  return schema[resource].removeMany(resource, schema[resource].fields);
};

const query = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  create,
  updateMany,
  remove,
  removeMany,
};

export default query;
