import {
  BooleanInput,
  Create,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CreateActions } from "../../components/ra/Actions";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";

const AdvertisementCreate = ({ onCancel, ...props }: any) => (
  <Create
    actions={<CreateActions />}
    title={"advertisement.createTitle"}
    {...props}
  >
    <SimpleForm redirect="list" defaultValue={{ type: "advertisement" }}>
    <TextInput label={"page.slug"} source="slug" />
      <BooleanInput label={"page.published"} source="published" />
      <NumberInput label={"general.ordering"} source="ordering" />
      {/* <TextInput label={"page.slug"} source="slug" /> */}
      <TextInput validate={[required()]} label={"page.title"} source="title" />
      <TextInput fullWidth label={"page.description"} source="description" />
      <RichTextEditor label={"page.content"} source="content" />
    </SimpleForm>
  </Create>
);

export default AdvertisementCreate;
