import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  required,
} from "react-admin";
import { CreateActions } from "../../components/ra/Actions";
import RichTextEditor from "../../components/ra/inputs/RichTextEditor";

const ArticleCreate = ({ ...props }) => (
  <Create actions={<CreateActions />} title={"article.createTitle"} {...props}>
    <SimpleForm redirect="list" defaultValue={{ type: "article" }}>
      {/* <NumberInput label={"general.ordering"} source="ordering" /> */}
      <TextInput label={"page.slug"} source="slug" />
      <BooleanInput label={"page.published"} source="published" />
      {/* <ReferenceArrayInput source="tagIds" reference="Tag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput> */}
      <TextInput label={"page.title"} validate={[required()]} source="title" />
      <TextInput fullWidth label={"page.description"} source="description" />
      <RichTextEditor label={"page.content"} source="content" />
    </SimpleForm>
  </Create>
);

export default ArticleCreate;
