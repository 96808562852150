import { Create, required, SimpleForm, TextInput } from "react-admin";
import { CreateActions } from "../../components/ra/Actions";

const TagCreate = ({ onCancel, ...props }: any) => (
  <Create actions={<CreateActions />} title={"tag.createTitle"} {...props}>
    <SimpleForm redirect="list">
      <TextInput label={"general.code"} source="name" />
      <TextInput
        validate={[required()]}
        label={"general.name"}
        source="title"
      />
    </SimpleForm>
  </Create>
);

export default TagCreate;
