import { Edit, required, SimpleForm, TextInput } from "react-admin";
import { EditToolbar } from "../../components/ra/Toolbars";

const RoleEdit = ({ ...props }) => (
  <Edit title={"role.editTitle"} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput validate={[required()]} label={"general.name"} source="name" />
      <TextInput
        validate={[required()]}
        label={"general.title"}
        source="title"
      />
    </SimpleForm>
  </Edit>
);

export default RoleEdit;
