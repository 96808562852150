import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  DateInput,
} from "react-admin";
import { EditActions } from "../../components/ra/Actions";

const FormDataEdit = ({ ...props }) => (
  <Edit actions={<EditActions />} title={"formData.editTitle"} {...props}>
    <SimpleForm>
      <TextInput label={"general.id"} source="id" />
      <TextInput label={"general.type"} source="type" />
      <TextInput label={"general.name"} source="name" />
      <TextInput label={"general.email"} source="email" />
      <TextInput label={"formData.telephone"} source="telephone" />
      <TextInput label={"formData.city"} source="city" />
      <TextInput label={"formData.street"} source="street" />
      <TextInput label={"formData.zip"} source="zip" />
      <TextInput
        fullWidth
        multiline
        maxRows={10}
        minRows={4}
        label={"formData.message"}
        source="message"
      />
      <BooleanInput label={"formData.gdprApproval"} source="gdprApproval" />
      <DateInput label={"general.createdAt"} source="createdAt" />
    </SimpleForm>
  </Edit>
);

export default FormDataEdit;
